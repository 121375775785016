import { PC_BASE_BLUEPRINT } from './PerformanceCheckConstants';
import performanceCheckUtil from '../utils/performanceCheckUtil';

const MAX_DATE = '9999-12-31';
const YN = { YES: 'Y', NO: 'N' };
Object.freeze(MAX_DATE);
Object.freeze(YN);

const DOC_TYPE = [
  {
    category: '고객접수서류',
    value: 'C_FRONT',
    label: '차량전면사진',
  },
  {
    category: '고객접수서류',
    value: 'C_DASHBOARD',
    label: '현재 기준 계기판 사진',
  },
  {
    category: '고객접수서류',
    value: 'C_CONTRACT',
    label: '매매계약서(매수인용)',
  },
  {
    category: '고객접수서류',
    value: 'C_CERTIFICATE',
    label: '자동차등록증',
  },
  {
    category: '고객접수서류',
    value: 'C_ETC',
    label: '기타서류',
  },
  {
    category: '고객접수서류',
    value: 'C_ORIGINALDOCS',
    label: '등록원부',
  },
  {
    category: '고객접수서류',
    value: 'C_VALUE',
    label: '차량가액',
  },
  {
    category: '고객접수서류',
    value: 'C_RECALL',
    label: '리콜',
  },
  {
    category: '고객접수서류',
    value: 'C_ETCDOCS',
    label: '기타서류(외판,튜닝)',
  },
  {
    category: '고객접수서류',
    value: 'C_CONSENT',
    label: '개인정보동의서',
  },
  {
    category: '삭제된고객서류',
    value: 'C_DELETE',
    label: '삭제된 고객서류',
  },
  {
    category: '매매상사접수서류',
    value: 'S_CHECK1',
    label: '성능기록부',
  },
  {
    category: '매매상사접수서류',
    value: 'S_CHECK2',
    label: '자동차등록원부(갑)',
  },
  {
    category: '매매상사접수서류',
    value: 'S_CONTRACT',
    label: '매매계약서(매도인용)',
  },
  {
    category: '진단서류',
    value: 'P_FRONT',
    label: '입고 차량전면사진',
  },
  {
    category: '진단서류',
    value: 'P_DASHBOARD',
    label: '입고 계기판사진',
  },
  {
    category: '진단서류',
    value: 'P_CHECK',
    label: '점검사진',
  },
  {
    category: '진단서류',
    value: 'P_PART',
    label: '부품명세서',
  },
  {
    category: '진단서류',
    value: 'P_OPINION',
    label: '소견서',
  },
  {
    category: '진단서류',
    value: 'P_LIST',
    label: '견적서',
  },
  {
    category: '수리서류',
    value: 'P_MAINTENANCE',
    label: '정비사진',
  },
  {
    category: '수리서류',
    value: 'P_COMPLETED',
    label: '부품명세서(수리)',
  },
  {
    category: '수리서류',
    value: 'P_INSURANCE',
    label: '보험금청구동의서',
  },
  {
    category: '수리서류',
    value: 'P_INSURANCE_TEMPLATE',
    label: '보험금청구동의서(빈 양식)',
  },
  {
    category: '수리서류',
    value: 'P_REGISTRATION',
    label: '사업자등록증',
  },
  {
    category: '수리서류',
    value: 'P_BANKBOOK',
    label: '사업자등록증사본',
  },
  {
    category: '수리서류',
    value: 'P_ETC',
    label: '기타',
  },
  {
    category: '수리서류',
    value: 'P_APPROVED',
    label: '승인견적서',
  },
];

const COUNT_PER_PAGE = [5, 10, 20, 30, 50, 100, 500, 1000, 5000, 10000];
const TRVL_DSTNC_RANGES = [
  { label: '2만 이하', max: 20000 },
  { label: '4만 이하', max: 40000 },
  { label: '6만 이하', max: 60000 },
  { label: '8만 이하', max: 80000 },
  { label: '10만 이하', max: 100000 },
  { label: '12만 이하', max: 120000 },
  { label: '14만 이하', max: 140000 },
  { label: '16만 이하', max: 160000 },
  { label: '18만 이하', max: 180000 },
  { label: '20만 이하', max: 200000 },
];
const ENTERPRISE_TYPES = [
  { value: 'AD', label: '협회직영' },
  { value: 'FR', label: '프랜차이즈(법인)' },
];
const CHECK_STATES = [
  { value: 'WA', label: '대기' },
  { value: 'TS', label: '임시저장' },
  { value: 'TR', label: '작성완료' },
  { value: 'DE', label: '삭제됨' },
];
const TRANSFER_STATES = [
  { value: 'W', label: '미전송' },
  { value: 'F', label: '전송실패' },
  { value: 'N', label: '최초전송됨' },
  { value: 'U', label: '업데이트됨' },
  { value: 'R', label: '주행거리확인' }, // ?
  { value: 'D', label: '삭제됨' },
  { value: 'X', label: 'TS공단 에러' },
];

const PAYMENT_METHOD = [
  // { label: '결제대기', value: '' },
  { label: '신용카드', value: 'CARD' },
  { label: '현금', value: 'CASH' },
  { label: '계좌이체', value: 'TRAN' },
];

/**
 * 수입차량이지만 보험사에서는 국산에 해당하는 차량
 * @value 차량명 CNM
 */
const MADE_AT_D = [
  { label: '쉐보레 임팔라', value: 'IMPALA' },
  { label: '쉐보레 베리타스', value: '베리타스' },
  { label: '쉐보레 이쿼녹스', value: 'EQUINOX' },
  { label: '르노 QM3', value: 'QM3' },
  { label: '르노 클리오', value: 'CLIO' },
  { label: '르노 트위지', value: 'TWIZY' },
];

const VEHICLE_TYPE_LEVEL1 = [
  { label: '국산', value: 'D' },
  { label: '수입', value: 'F' },
];
const VEHICLE_TYPE_LEVEL2 = PC_BASE_BLUEPRINT['VHCTY_ASORT_CODE'].valueItems;
const VEHICLE_TYPE_LEVEL3 = [
  {
    lvl1: 'D',
    lvl2: '1',
    label: '초소형/소형A(1000cc 이하)',
    value: 'SA',
    filter: (displace, maxPerson) => {
      return displace >= 0 && displace <= 1000 && maxPerson < 7;
    },
  },
  {
    lvl1: 'D',
    lvl2: '1',
    label: '소형B(1600cc 이하)',
    value: 'SB',
    filter: (displace, maxPerson) => {
      return displace > 1000 && displace <= 1600 && maxPerson < 7;
    },
  },
  {
    lvl1: 'D',
    lvl2: '1',
    label: '중형(2000cc 이하)',
    value: 'MD',
    filter: (displace, maxPerson) => {
      return displace > 1600 && displace <= 2000 && maxPerson < 7;
    },
  },
  {
    lvl1: 'D',
    lvl2: '1',
    label: '대형(2000cc 초과)',
    value: 'LG',
    filter: (displace, maxPerson) => {
      return displace > 2000 && maxPerson < 7;
    },
  },
  {
    lvl1: 'D',
    lvl2: '1',
    label: '다인승(1,2종/7~10인승 이하)',
    value: 'MP',
    filter: (displace, maxPerson) => {
      // return maxPerson >= 7 && maxPerson <= 10;
      return displace >= 0 && maxPerson >= 7 && maxPerson <= 10;
    },
  },
  {
    lvl1: 'D',
    lvl2: '1',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'D',
    lvl2: '2',
    label: '1종(26~35인승 이하)',
    value: '1C',
    filter: (displace, maxPerson) => {
      // return displace || (maxPerson >= 26 && maxPerson <= 35);
      return displace >= 0 && maxPerson >= 26 && maxPerson <= 35;
    },
  },
  {
    lvl1: 'D',
    lvl2: '2',
    label: '2~3종(11~25인승 이하)',
    value: '2C',
    filter: (displace, maxPerson) => {
      // return displace || (maxPerson >= 11 && maxPerson <= 25);
      return displace >= 0 && maxPerson >= 11 && maxPerson <= 25;
    },
  },
  {
    lvl1: 'D',
    lvl2: '2',
    label: '경승합(10인 이하 & 1000cc 미만)',
    value: 'LM',
    filter: (displace, maxPerson) => {
      return displace < 1000 && maxPerson <= 10;
    },
  },
  {
    lvl1: 'D',
    lvl2: '2',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'D',
    lvl2: '3',
    label: '2종(2.5톤 초과 5톤 이하)',
    value: '2C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'D',
    lvl2: '3',
    label: '3종(1톤 초과 2.5톤 이하)',
    value: '3C',
    filter: () => {
      return true;
    },
  },

  {
    lvl1: 'D',
    lvl2: '3',
    label: '4종(1톤 이하)',
    value: '4C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'D',
    lvl2: '3',
    label: '경화물',
    value: 'LF',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'D',
    lvl2: '3',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'D',
    lvl2: '4',
    label: '4종(1톤 이하)',
    value: '4C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'D',
    lvl2: '4',
    label: '경화물',
    value: 'LF',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'D',
    lvl2: '4',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'F',
    lvl2: '1',
    label: '초소형/소형A,B(1600cc 이하)',
    value: 'SM',
    filter: (displace, maxPerson) => {
      return displace >= 0 && displace <= 1600 && maxPerson < 7;
    },
  },
  {
    lvl1: 'F',
    lvl2: '1',
    label: '중형(2000cc 이하)',
    value: 'MD',
    filter: (displace, maxPerson) => {
      return displace > 1600 && displace <= 2000 && maxPerson < 7;
    },
  },
  {
    lvl1: 'F',
    lvl2: '1',
    label: '대형(2000cc 초과)',
    value: 'LG',
    filter: (displace, maxPerson) => {
      return displace > 2000 && maxPerson < 7;
    },
  },
  {
    lvl1: 'F',
    lvl2: '1',
    label: '다인승(1,2종/7~10인승 이하)',
    value: 'MP',
    filter: (displace, maxPerson) => {
      return displace >= 0 && maxPerson >= 7 && maxPerson <= 10;
    },
  },
  {
    lvl1: 'F',
    lvl2: '1',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'F',
    lvl2: '2',
    label: '1종(26~35인승 이하)',
    value: '1C',
    filter: (displace, maxPerson) => {
      return displace >= 0 && maxPerson >= 26 && maxPerson <= 35;
    },
  },
  {
    lvl1: 'F',
    lvl2: '2',
    label: '2~3종(11~25인승 이하)',
    value: '2C',
    filter: (displace, maxPerson) => {
      return displace >= 0 && maxPerson >= 11 && maxPerson <= 25;
    },
  },
  {
    lvl1: 'F',
    lvl2: '2',
    label: '경승합(10인 이하 & 1000cc 미만)',
    value: 'LM',
    filter: (displace, maxPerson) => {
      return displace < 1000 && maxPerson <= 10;
    },
  },
  {
    lvl1: 'F',
    lvl2: '2',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'F',
    lvl2: '3',
    label: '2종(2.5톤 초과 5톤 이하)',
    value: '2C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'F',
    lvl2: '3',
    label: '3종(1톤 초과 2.5톤 이하)',
    value: '3C',
    filter: () => {
      return true;
    },
  },

  {
    lvl1: 'F',
    lvl2: '3',
    label: '4종(1톤 이하)',
    value: '4C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'F',
    lvl2: '3',
    label: '경화물',
    value: 'LF',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'F',
    lvl2: '3',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'F',
    lvl2: '4',
    label: '4종(1톤 이하)',
    value: '4C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'F',
    lvl2: '4',
    label: '경화물',
    value: 'LF',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'F',
    lvl2: '4',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },
];

const REPORT_TYPE = {
  PERFORMANCE_CHECK_REPORT: {
    api: '/apis/report/performance-check',
  },
  INSURANCE_CERTIFICATE: {
    api: '/apis/report/insurance-certificate',
  },
};

const MESSAGE_STATUS = {
  READY: 'ready',
};

const CENTER_DOCS = [
  { label: '입고 차량전면사진', value: 'P_FRONT' },
  { label: '입고 계기판사진', value: 'P_DASHBOARD' },
  { label: '점검사진', value: 'P_CHECK' },
  { label: '부품명세서', value: 'P_PART' },
  { label: '소견서', value: 'P_OPINION' },
  { label: '견적서', value: 'P_LIST' },
];

const REPAIR_DOCS = [
  { label: '정비사진', value: 'P_MAINTENANCE' },
  { label: '부품명세서(수리)', value: 'P_COMPLETED' },
  { label: '보험금청구동의서', value: 'P_INSURANCE' },
  { label: '사업자등록증', value: 'P_REGISTRATION' },
  { label: '사업자통장사본', value: 'P_BANKBOOK' },
  // { label: '기타', value: 'P_ETC' },
];

const ACCEPT_FILE_TYPES = {
  'image/*': [],
  'application/pdf': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    [],
  'application/vnd.hancom.hwp': ['.hwp'],
};

export {
  DOC_TYPE,
  MAX_DATE,
  YN,
  COUNT_PER_PAGE,
  TRVL_DSTNC_RANGES,
  ENTERPRISE_TYPES,
  CHECK_STATES,
  TRANSFER_STATES,
  VEHICLE_TYPE_LEVEL1,
  VEHICLE_TYPE_LEVEL2,
  VEHICLE_TYPE_LEVEL3,
  REPORT_TYPE,
  MESSAGE_STATUS,
  PAYMENT_METHOD,
  MADE_AT_D,
  CENTER_DOCS,
  REPAIR_DOCS,
  ACCEPT_FILE_TYPES,
};
